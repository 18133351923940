.rangeLabel {
  background: #f6e095;
  padding: 2px 5px;
  margin-right: 3px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
}

.birdsPagination ul {
  justify-content: center;
}

.birdsPagination ul li a {
  padding: 10px 15px;
  color: #877e90;
}

.birdsPagination ul li.previous a {
  font-weight: bold;
}

.birdsPagination ul li.next a {
  font-weight: bold;
}

.birdsPagination ul li.active a {
  background: transparent;
  border: 0.5px solid #374400;
  border-radius: 100%;
  box-shadow: none;
  color: #47404f !important;
}

.capturingImg {
  position: relative;
}

.capturingImgone {
  position: absolute;
  width: 100%;
  /* width: 48vw;
    max-width: 120%; */
  width: calc(100% + 10rem);
  z-index: 1;
}

.capturingImgOne {
  margin-top: 40px;
}

.tanjeImg {
  width: 70%;
}

.PoweringPetImgtwo img {
  width: 100%;
  margin-top: -280px;
}

.premove {
  padding-top: 10px !important;
  position: relative;
}

.TNJ {
  position: relative;
}

.TNJ img {
  position: absolute;
  right: 0;
  top: 10px;
  width: 119px;
}

.Discord {
  text-align: right;
}

.Discord img {
  width: 60px;
}

.OnBird {
  width: 100px;
  position: absolute;
  right: 0;
  top: 65px;
}

.qr-code-btn,
.qr-code-btn:hover,
.qr-code-btn:focus,
.qr-code-btn:active {
  background: transparent !important;
  font-size: 40px;
  border: none;
  padding: 0 16px;
  box-shadow: none !important;
}

/* 18 April 2022 - SoftRadix Developer */
.qr-code-instruction .modal-content {
  border-radius: 20px;
  padding: 18px 0;
}

.timer-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.timer-sec span {
  font-size: 15px !important;
  margin-left: 10px;
}

.instructions {
  margin-left: 18px;
}

.instructions-list {
  padding-left: 20px;
}

.instructions-list .icon-info {
  background-color: #f2f2f2;
  padding: 7px 15px;
}

.instructions-list h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
  color: #41525d;
  margin-bottom: 3rem;
}

.instructions-list li {
  list-style: inherit;
  font-size: 18px;
  line-height: 35px;
  color: #41525d;
}

.greeting__title_decorative_waitlist {
  font-weight: 400;
  font-size: 96px;
  font-family: "Metal Mania", cursive;
  text-transform: uppercase;
  margin: 0 0 16px;
  line-height: 1.2;
  color: #47404F;
}

.greeting__text_waitlist {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #877E90;
}

.greeting__title_waitlist {
  font-family: "Metal Mania", cursive;
  font-weight: 400;
  color: #F6E095;
  font-size: 36px;
  line-height: 1.2;
}

.waitlist-wrapper {
  display: flex;

}

.waitlist-wrapper .iphone-image {
  flex-basis: 40%;
}

.waitlist-wrapper .content-waitlist {
  flex-basis: 60%;
  flex-grow: 1;
}

.waitlist-wrapper .android-image {
  flex-basis: 40%;
  text-align: center;
}

.waitlist-wrapper .android-image img {
  width: 75%;
}

.chooseValueOption .disabled {
  background-color: #bdbcbce0 !important;
}

@media screen and (max-width: 991px) {
  .premove {
    padding-top: 10px !important;
  }

  .capturingImgone {
    width: 70% !important;
    left: 50%;
    transform: translateX(-50%);
  }

  .capturingImgOne {
    margin-top: 120px;
  }

  .capturingImgrow {
    flex-direction: column-reverse !important;
  }

  .tanjeImgrow {
    flex-direction: column-reverse !important;
  }

  .PoweringPetImgtwo img {
    margin-top: -250px;
  }

  .tanjeImg {
    max-width: 380px;
  }

  .waitlist-wrapper {
    flex-wrap: wrap;
  }

  .waitlist-wrapper .iphone-image {
    flex-basis: 50%;
  }

  .waitlist-wrapper .content-waitlist {
    flex-basis: 100%;
    flex-grow: 1;
    order: -1;
  }

  .waitlist-wrapper .android-image {
    flex-basis: 50%;
  }

  .waitlist-wrapper .android-image img {
    width: 75%;
  }
}

@media screen and (max-width: 767px) {
  .PoweringPetImgtwo img {
    margin-top: -160px;
  }

  .capturingImgOne {
    margin-top: 70px;
  }
}