@charset "utf-8";

/*  ###########   Responsive Queries  ############ */
@media (min-width: 1201px) and (max-width: 1420px) {
  .mainNavbar .navbar-nav .nav-item {
    background-size: 130px 68px;
    min-width: 135px;
    margin: 0 4px;
  }
  .siteNav.home-header .mainNavbar .navbar-nav .nav-item .nav-link {
    font-size: 16px;
  }
}

@media screen and (max-width: 1130px) and (min-width: 768px) {
  .mainNavbar .navbar-nav .nav-item {
    background-position: top;
    background-size: 80px 50px;
    background-repeat: no-repeat;
    padding: 0px 2px 20px;
    margin: 0 5px;
    min-height: 0;
    min-width: 80px;
    text-align: center;
    font-family: "headbanger";
    white-space: nowrap;
  }
  .siteNav .navbar-brand .headerLogo {
    max-width: 120px;
  }
  /* .header__hero-img-wrapperr > div img:nth-child(2){
    width:100%;
  } */
  .mainNavbar .navbar-nav .nav-item.headerLogin {
    background-size: 101px 43px;
    background-position: top;
    min-width: 101px;
    margin-top: 5px;
  }
  .siteNav.home-header .mainNavbar .navbar-nav .nav-item .nav-link {
    font-size: 10px;
  }
  .customHeader {
    /* padding-top:25px; */
  }
  .siteNav.home-header .mainNavbar .navbar-nav .nav-item.headerLogin .nav-link {
    font-size: 12px;
  }
}
@media screen and (min-width: 1201px) {
  .header__hero-img-wrapperr > div img:nth-child(1) {
    display: block;
    margin: 0px auto;
    max-width: 240px;
    transform: translate(-6px, 0px);
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 991px) {
  .siteNav .mainNavbar {
    /* padding: 6px 10px 12px 15px; */
  }
  .siteNav .navbar-brand .headerLogo {
    max-width: 100px;
  }
  /* Heading Fontsize */
  .promo__title_decorative,
  .greeting__title_decorative,
  .loginDataSec h1 {
    font-size: 42px !important;
  }
  .section.greeting1::after {
    background: none;
  }
  /* .header__hero-img-wrapper {
    top: calc(22 * 9vw / 8);
    width: calc(385 * 8vw / 36);
    left: calc(55 * 5vw / 36);
  } */
  .header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .greeting__text,
  .promo__text,
  .advices__text {
    font-size: 16px;
    line-height: 22px;
  }
  .greeting__content {
    padding: 60px 0 0;
  }
  .greeting__title {
    font-size: 26px;
    text-align: center;
  }
  .greeting {
    background-image: none;
  }
  .promo__title {
    font-size: 22px;
  }
  .promo__text p + p {
    margin: 0px;
  }

  .alice-carousel__stage-item {
    padding: 0 0px;
  }
  .promo__content {
    padding-top: 10px;
  }
  .promo__container {
    /* padding: 50px 15px 20px 15px; */
  }
  .promo__carousel-item {
    max-width: 100% !important;
  }
  .advices {
    padding: 30px 0 50px 0;
  }
  .advices__imgs {
    flex-direction: inherit;
  }
  .advices__title {
    font-size: 22px;
    line-height: 33px;
    margin: 0 0 15px;
  }
  .advices__img {
    margin: 10px 4px 5px 4px;
    max-width: calc(100% / 3 - 8px);
  }
  .bottomCta {
    padding: 30px 0 60px 0;
  }
  .bottomCta .cta_title {
    font-size: 22px;
    line-height: 30px;
    margin: 0;
  }
  .yellowBtn,
  .greyLineBtn {
    font-size: 16px;
    min-width: 160px;
    padding: 10px 15px 12px 15px;
  }
  .footer__logo {
    max-width: 200px;
  }
  .socialMedia li a img {
    max-width: 38px;
  }
  .socialMedia {
    margin: 10px 0 15px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #d8e0ba59;
  }
  .footer {
    padding-top: 20px;
  }
  .footer ul {
    text-align: center;
  }
  .footerLinks li a {
    font-size: 16px;
  }
  /* .newsletterImg {max-width: 280px;} */
  .newsletterSec form {
    padding-top: 12px;
  }
  .innerPageTopSpace {
    padding-top: 100px;
  }
  .petExchangeTopSec {
    padding: 0px 0 15px 0;
    background: none;
    /* max-width: 450px; */
  }
  .petExplorerPage .petExchangeTopSec {
    background: none;
  }
  .pageTitle {
    font-size: 30px;
    margin: 10px 0 15px 0;
  }
  .petExchangeTopSecData .pageTitle {
    margin: 0 0 18px 0;
  }
  .petExchangeTopSecData ul li {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .leaderboardsTopSec {
    padding: 15px 5px 22px 5px;
  }
  .prizePoolTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .prizeCardWrapper {
    padding-top: 15px;
  }
  .prizeCard {
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  .prizeCard img {
    max-width: 60px;
  }
  .prizeCard .winnerAmount strong {
    font-size: 24px;
  }
  .prizeCardWrapper .col-md-4:last-child .prizeCard {
    margin-bottom: 0;
  }
  .tblTopFilter .tblTopFilterLeftSec .form-control {
    max-width: 150px;
    margin-right: 15px;
  }
  .tblTopFilter input[type="text"] {
    background-size: 16px;
    min-width: inherit;
  }
  .table-responsive .table {
    min-width: 580px;
  }
  .petExchangeBtmSec {
    padding-bottom: 20px;
  }
  .tblBottomInfo {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0;
  }
  .petExchangeTopBuySec {
    margin-top: 10px;
    padding: 5px;
  }
  .peBuyGroup img {
    max-width: 80px;
  }
  .peBuyGroup div {
    font-size: 22px;
  }
  .chestDetailsTopSec {
    padding: 30px 0 30px 0;
  }
  .chestDetailsBtmSec {
    margin-top: 22px;
  }
  .chestDetailsBtmSec .chestImgSec {
    margin-bottom: 22px;
    padding-bottom: 30px;
  }
  .chestDetailsBtmSec .landChest_sider_img {
    height: auto;
  }
  .chestDetailsBtmSec .chestImgSec .alice-carousel {
    position: static;
  }
  .chestDetailsBtmSec .chestImgSec .alice-carousel__dots {
    margin-top: 0px;
  }
  .chestImgSec .alice-carousel__prev-btn-item,
  .chestImgSec .alice-carousel__next-btn-item {
    top: auto;
    bottom: 0;
    left: 50%;
    right: auto;
  }
  .chestImgSec .alice-carousel__prev-btn-item {
    transform: translate(-150%, 0);
  }
  .chestImgSec .alice-carousel__next-btn-item {
    transform: translate(50%, 0);
  }
  .chooseValueOption .form-control {
    padding: 5px 12px;
    max-width: 100px;
    max-height: 42px;
  }
  .chooseRadioOption .mb-3 .form-label {
    font-size: 20px;
    padding: 8px 5px;
    min-width: 45px;
    margin: 0;
  }
  .purchaseToggleData {
    margin-bottom: 30px;
    padding: 15px;
  }
  .totalPurchase .yellowBtn {
    font-size: 16px;
    padding: 8px 15px;
    min-width: inherit;
  }
  .chooseValueOption {
    align-items: start;
  }
  .chooseRadioOption .mb-3 {
    margin: 0 5px 5px 0 !important;
  }
  .totalPurchase {
    padding-top: 10px;
  }
  .itemsSelectBox .customCheckbox {
    margin-bottom: 10px;
  }
  .petPriceSec .yellowBtn,
  .petPriceSec .greyLineBtn {
    min-width: 120px;
  }
  .breedTopSec .breedTopSpaceSec {
    padding-top: 10px;
  }
  .loginInner {
    padding: 30px;
    max-width: 450px;
    margin: 0 auto;
  }
  .signupLink {
    padding-top: 30px;
  }
  .loginInner .loginScreenLogo {
    max-width: 150px;
    margin: -10px 0 20px 0;
  }
  .mainNavbar .dropdown .dropdown-menu::after,
  .mainNavbar .dropdown .dropdown-menu::before {
    display: none;
  }
  .filterButton,
  .filterButton:hover,
  .filterButton:focus,
  .filterButton:active {
    background-image: url("../assets/imgs/filter.svg");
    background-repeat: no-repeat;
    background-position: center left 20px;
    background-color: #fff !important;
    color: #374400 !important;
    padding: 10px 25px 10px 55px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    border: 2px solid #374400;
    border-radius: 8px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: none;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 160px;
  }
  .petExchangeBtmSec .filterWrapper {
    /* display: block !important; */
    position: fixed;
    top: 0px;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
  }
  /* .petExchangeBtmSec .filterWrapper.show {
    display: none !important;
  } */
  .filtercloseButton {
    background: url("../assets/imgs/closeIcon.svg") no-repeat left;
    height: 20px;
    border: none;
    background-size: 14px;
    background-position: center;
    width: 20px;
    cursor: pointer;
    background-color: transparent !important;
    position: absolute;
    right: 10px;
    padding: 0;
    display: inline-block;
  }
  .filterTitle .customCheckbox {
    margin-right: 40px;
  }
  .filterWrapper .filterCard {
    height: 100%;
  }
  .BuildingSales,
  .collectRare,
  .petBreeding {
    background: none !important;
  }

  .collecRare_carosel .alice-carousel__stage {
    /* --spacing: 20px; */
  }
  .collecRare_carosel .alice-carousel__stage-item {
    padding: 20px 20px;
  }
  .collecRare_carosel .alice-carousel__stage-item img {
    width: 100%;
  }
  .promo__carousel .alice-carousel__wrapper {
    padding-bottom: 0px;
  }

  .collecRare_carosel .alice-carousel__next-btn,
  .collecRare_carosel .alice-carousel__prev-btn {
    top: calc(100% - 24px);
    left: 50%;
    transform: translateX(-50%);
    margin-left: 40px;
    display: block;
  }
  .collecRare_carosel .alice-carousel__prev-btn {
    margin-left: -40px;
  }
  .collecRare_carosel
    .alice-carousel__prev-btn
    .alice-carousel__prev-btn-wrapper {
    display: block;
  }
  .section {
    padding-top: 48px;
  }
  .promo.section {
    padding-top: 55px !important;
  }
  .capturing {
    padding: 30px 0 0;
  }
  .collectRare .container {
    padding: 10px 15px 0;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
  .navbar-expand-xl .navbar-collapse {
    height: auto !important;
  }
}

@media only screen and (max-width: 1010px) {
  .tanjeaTheme .chestDetailsTopSec {
    background-color: #4f2182;
  }
  .promo__contentparagraph {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .home-header {
    padding: 0 14px 0 10px;
  }
  .hideShow {
    display: block;
  }
  .ShowHide {
    display: none;
  }
  #ShowHide {
    display: none;
  }
  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    padding: 0.25rem;
    box-shadow: none;
    outline: none;
  }
  .navbar-toggler-icon {
    background-image: url("../assets/imgs/toggle-icon.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 40px;
    height: 32px;
    vertical-align: middle;
    background-position: center;
    background-size: 100%;
  }
  .CustomNavLink {
    flex-direction: column;
  }
  /* .siteNav .mainNavbar {
    padding: 10px 10px 12px 15px;
  } */
  .siteNav .navbar-brand .headerLogo {
    max-width: 120px;
  }
  header .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    z-index: 999;
    visibility: hidden;
    background-color: #faffe7;
    transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  header .navbar-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }
  .mobMenuHeader {
    display: flex;
    padding: 6px 10px 12px 15px;
    align-items: center;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: #fefffa;
    border-bottom: 1px solid #d8e0ba;
    justify-content: space-between;
  }
  .closeBurgerMenu .navbar-toggler-icon {
    display: none;
  }
  .closeBurgerMenu,
  .closeBurgerMenu:hover,
  .closeBurgerMenu:focus,
  .closeBurgerMenu:active {
    background: url("../assets/imgs/closeIcon.svg") no-repeat left;
    height: 30px;
    border: none;
    background-size: 18px;
    background-position: center;
    width: 30px;
    cursor: pointer;
    margin-right: 3px;
    background-color: transparent !important;
  }
  header .navbar-collapse .navbar-nav .nav-link {
    padding: 10px 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  header .navbar-collapse .navbar-nav {
    padding: 20px !important;
    max-width: 100%;
    width: 100%;
    margin-top: 75px;
  }
  .mainNavbar .navbar-nav .nav-item {
    padding: 0;
    background-size: 150px 70px;
    min-width: 150px;
    margin: 5px 0px;
  }
  .headerLogin .yellowBtn {
    padding: 11px 10px 16px 10px !important;
    max-width: 150px;
    margin-top: 20px;
  }
  .mainNavbar .navbar-nav .nav-item.userPicSec {
    margin-top: 25px;
    border-top: 1px solid #ddd;
    padding-top: 25px;
    text-align: center;
  }
  .siteNav .headerUserPic {
    width: 50px;
    height: 50px;
  }
  .mainNavbar .dropdown.userPicSec .dropdown-menu {
    margin-top: 15px !important;
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
  }
  .siteNav.home-header .mainNavbar .navbar-nav .nav-item .nav-link {
    color: #374400;
  }

  .tanjeaTheme .header__logo {
    display: none !important;
  }
  .iosSwitch {
    margin-top: 0;
  }
  .siteNav .navbar-brand .headerlogo {
    transform: none;
    max-width: 116px;
  }
  .container,
  .container-sm {
    max-width: 100%;
  }

  .footerLinks li {
    display: inline-block;
    width: 50%;
    padding: 10px 0;
  }
  .petExchangeTopSecData {
    padding-left: 0;
  }
  .newsletterSec {
    padding: 30px 0 40px 0;
    border-top: 1px solid #d8e0ba59;
    margin-top: 20px;
  }
  .chestDetailsBtmSec .chestDetailsSec {
    padding-top: 20px;
  }
  .chestCard .nav {
    flex-wrap: inherit;
  }
  .chestCard .chestCardCol {
    padding: 12px 0 10px 0;
  }
  .chestCard .nav-pills .nav-link.active,
  .chestCard .nav-pills .show > .nav-link {
    border-radius: 8px 8px 35px 8px;
  }
  .chestCard .chestCardCol {
    padding: 12px 0 10px 0;
  }
  .chestCard .chestCardCol img {
    max-width: 100%;
  }
  .chestCard .chestCardCol h4 {
    font-size: 18px;
    margin: 15px 0 0 0;
  }
  .chestCard .nav-pills .nav-item {
    padding: 10px 5px;
  }
  .myAccountPic_info h4 {
    font-size: 22px;
    padding: 10px 0 20px 0;
  }
  .myaccountNav ul li {
    font-size: 16px;
    padding: 0 4px;
    min-width: 20%;
  }
  .myaccountNav {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .petExchangeTopSec .birdImg {
    max-width: 450px;
  }
  .birdsPagination {
    padding: 10px;
    margin-top: 10px;
    max-width: 450px;
    margin: 10px auto;
  }
  .birdsPagination .pagination .page-item .page-link {
    padding: 6px 12px;
    margin: 0 8px;
  }
  .birdsPagination .pagination .page-item [aria-label="Next"],
  .birdsPagination .pagination .page-item [aria-label="Previous"] {
    font-size: 30px;
    height: 20px;
    line-height: 28px;
    padding: 0;
  }
  .birdsCard {
    max-width: 450px;
    margin: 10px auto 20px auto;
  }
  .myAccountPic_info img {
    width: 150px;
    height: 150px;
  }
  .myAccountLR_info label {
    min-height: 36px;
  }
  .breedTopSec .addBreed {
    height: 300px;
  }
  .spendGoldModel .modelInnerData h5 {
    font-size: 24px;
    margin: 25px 0;
  }
  .spendGoldModel .modelInnerData img {
    width: 150px;
  }
  .loginWrapper {
    min-height: inherit;
    margin-top: 50px;
    padding: 50px 0;
  }
  .chestPurchaseSec .purchaseBtnToggle {
    width: 100%;
  }
  .promo__carousel .alice-carousel__prev-btn {
    left: -10px;
    width: 35px;
    height: 35px;
  }
  .promo__carousel .alice-carousel__next-btn {
    right: -10px;
    width: 40px;
    height: 40px;
  }
  .promo__carousel .alice-carousel__next-btn-item,
  .promo__carousel .alice-carousel__prev-btn-item {
    width: 40px;
    height: 40px;
  }
  .collecRare_carosel .alice-carousel__next-btn,
  .collecRare_carosel .alice-carousel__prev-btn {
    width: 35px;
    height: 35px;
  }
  .collecRare_carosel .alice-carousel__next-btn-item,
  .collecRare_carosel .alice-carousel__prev-btn-item {
    width: 35px;
    height: 35px;
  }
  .tanjeaTheme .chestDetailsTopSec {
    background-color: #513683;
  }
  .chestDetailsTopSec {
    background: #7a961f;
  }
}
@media only screen and (max-width: 575px) {
  .tblTopFilter input[type="text"] {
    min-width: 100%;
  }
  .customer_linking {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .petExchangeTopSec .birdImg {
    max-width: 100%;
  }
  .petExchangeTopSecData {
    padding-left: 0;
    padding-top: 20px;
  }
  .chestDetailsBtmSec .chestDetailsSec {
    padding-top: 0px;
  }
  .myAccountPic_info img {
    height: auto;
  }
  .myaccountNav ul li .nav-link {
    padding: 15px 3px;
  }
  .loginInner {
    padding: 20px;
  }
  .petExchangeTopSecData ul li {
    width: 100%;
  }
  header .navbar-collapse .navbar-nav .nav-link {
    font-size: 16px;
    padding: 7px 0;
  }
  .PoweringPetImgtwo img {
    margin-top: -100px;
  }
  /* .birdsCard {
    max-width: 280px;
  } */
  .birdsCard ul {
    display: flex;
    padding: 20px 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .container {
    max-width: 95%;
  }
  .leaderboardsTopSec {
    padding: 15px 5px 5px 5px;
  }
  .tblBottomInfo {
    font-size: 16px;
    margin: 10px 0;
  }
  .petExchangeBtmSec {
    padding-bottom: 30px;
  }
  .prizeCard {
    margin-bottom: 15px;
  }
  .myAccountPic_info img {
    width: 150px;
    height: 150px;
  }
  .breedTopSec .petExchangeTopSecData ul li {
    width: 100%;
  }
  .loginWrapper {
    min-height: inherit;
    margin-top: 50px;
    padding: 50px 0;
  }
  .promo__carousel {
    margin: 30px auto;
  }
}
@media screen and (min-width: 576px) and (max-width: 660px) {
  .breedTopSec .petExchangeTopSecData ul li {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .birdsCard ul li {
    width: 50%;
  }
  .breedTopSec .breedTopSpaceSec {
    padding-top: 120px;
  }
  .breedTopSec .addBreed {
    height: 275px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .promo__title_decorative,
  .greeting__title_decorative {
    font-size: 52px !important;
  }
  .siteNav .headerUserPic {
    width: 38px;
    height: 38px;
  }
  .headerLogin .yellowBtn {
    min-width: 80px;
    padding: 6px 10px 6px 10px;
  }
  .mainNavbar .navbar-nav .nav-item {
    /* padding: 0 3px; */
  }
  .header__logo {
    max-width: 250px;
    top: 120px;
  }
  .footerLinks li {
    padding: 12px 0;
  }
  .footerLinks li a {
    font-size: 16px;
  }
  .advices {
    padding: 40px 0 50px 0;
  }
  .PoweringPetImgtwo img {
    width: 100%;
    margin-top: -200px !important;
  }
}
@media (max-width: 992px) {
  section.greeting1 > .container > .row > div img {
    width: calc(300px + (500 - 280) * (100vw - 300px) / (1200 - 300));
    margin: 0px auto;
  }
  section.greeting1 > .container > .row > div {
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .promoImgeHidden {
    display: none;
  }
  .TNJ {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .custom_checkbox{
    width:40% !important
  }
  .custom_tabs{

    padding: 20px 0px;
  }
  .custom_tabs button{
    margin: 0px;
  }
}